import { fetchZip } from '../Map/mapboxApi';

export const flyToZip = async (mapRef, selectedZip) => {
    const zip = await fetchZip(selectedZip.name);
    if (!zip.body.features.length === 0) {
      window.alert('This zip is not available in the map ' + selectedZip.name);
      return;
    }
    
    const zipCenterCoord = zip.body.features[0].center;
    mapRef.current.flyTo({
      center: zipCenterCoord,
      essential: true,
      zoom: 0,
    });
  };