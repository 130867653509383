export const SELECT_MARKET = 'SELECT_MARKET';
export const UPDATE_MARKET_DATA = 'UPDATE_MARKET_DATA';
export const SET_MARKET_ID_FILTER = 'SET_MARKET_ID_FILTER';

export const updateMarketData = (newData) => {
    return {
        type: UPDATE_MARKET_DATA,
        payload: newData,
    };
};

export const selectMarket = (marketId) => {
    return {
        type: SELECT_MARKET,
        payload: marketId,
    };
};

export const setMarketIdFilter = (marketIdFilter) => {
    return {
        type: SET_MARKET_ID_FILTER,
        payload: marketIdFilter,
    };
};