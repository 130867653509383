import { SET_AREAS, SET_AREA_LOADING } from "./areaActions";

const initialState = {
  areaList: [],
  areaLoading: false,
};

const areasReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AREAS:
      return {
        ...state,
        areaList: action.payload,
      };
    case SET_AREA_LOADING:
      return {
        ...state,
        areaLoading: action.payload,
      };
    default:
      return state;
  }
};

export default areasReducer;
