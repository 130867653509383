// MapboxConfig.js

export const INITIAL_VIEW_STATE = {
  longitude: -84.425034,
  latitude: 33.738045,
  zoom: 7,
};

export const MAP_STYLE = "mapbox://styles/mapbox/light-v11";

export const VECTOR_SERVER_URL = process.env.REACT_APP_MAP_TILE_HOST;

export const MAP_BOUNDS = {
  type: "Polygon",
  coordinates: [
    [
      [-86.108895, 32.557273],
      [-81.517484, 32.557273],
      [-81.517484, 34.402162],
      [-86.108895, 34.402162],
      [-86.108895, 32.557273],
    ],
  ],
  crs: {
    type: "name",
    properties: {
      name: "EPSG:4326",
    },
  },
};

export const vectorServer = VECTOR_SERVER_URL;

export const VECTOR_SERVER_TEST = {
  source: "background-zips",
  id: "background-zips",
  sourceLayer: "public.zips",
  url: vectorServer + "public.zips/{z}/{x}/{y}.pbf",
};

export const MARKET_SHAPES = {
  source: "atm.market_shapes",
  id: "atm.market_shapes",
  sourceLayer: "atm.market_shapes",
  url: vectorServer + "atm.market_shapes/{z}/{x}/{y}.pbf",
};

export const COMMUNITY_POINTS = {
  source: "background-points",
  id: "background-points",
  sourceLayer: "public.ffp_areas",
  url: vectorServer + "public.ffp_areas/{z}/{x}/{y}.pbf",
};
