import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const SearchBar = ({handleSubmit}) => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      const lowerCasedQuery = query.toLowerCase();
      handleSubmit(lowerCasedQuery);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [query, handleSubmit]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <form className="area-search-form">
      <label htmlFor="area-search-input" />
      <input
        id="area-search-input"
        className="area-search-input"
        type='text'
        name='query'
        placeholder={'Filter by Area, Market, Zip Code, Agent'}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </form>
  );
};

SearchBar.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default SearchBar;
