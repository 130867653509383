// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-behavior-toggle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  color: #ffffff;
  z-index: 100;
  padding: 0;
  font-weight: bold;
  overflow-y:auto;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MapBehaviorToggle/MapBehaviorToggle.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,SAAS;EACT,cAAc;EACd,YAAY;EACZ,UAAU;EACV,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".map-behavior-toggle {\n  display: inline-block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  margin: 0;\n  color: #ffffff;\n  z-index: 100;\n  padding: 0;\n  font-weight: bold;\n  overflow-y:auto;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
