import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const useUserProfile = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      setIsLoading(true);
      try {
        if (!authState || !authState.isAuthenticated) {
          // When the user isn't authenticated, forget any user info
          setUserInfo(null);
        } else {
          // You can also get user information from the `/userinfo` endpoint
          const info = await oktaAuth.getUser();
          setUserInfo(info);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, [authState, oktaAuth]);

  return { userInfo, isLoading, error };
};

export default useUserProfile;



export const useIsUserInGroup = (groupName, userProfile) => {
  if (userProfile && userProfile.groups) {
    return userProfile.groups.includes(groupName);
  }
  return false;
};