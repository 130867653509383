import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { split, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { onError } from "@apollo/client/link/error";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import BeatLoader from "react-spinners/BeatLoader";
import PropTypes from "prop-types";
import store from "./redux/store";
import { Provider as ReduxProvider } from "react-redux";
import AppProvider from "./AppProvider";
import useUserProfile from "./oktaUtils";
import LoadingProvider from "./LoadingProvider";
import LoadingScreen from "./LoadingScreen";
import "./App.css";
import { OKTA_CONFIG } from "./okta-config";
import MapContext from "../src/components/MapParent/MapContext";
import logErrorToCloudWatch from "./aws-cloudwatch-logger";
import ErrorBoundary from "./ErrorBoundary";

// Lazy load MapParent component
const MapParent = lazy(() => import("../src/components/MapParent/MapParent"));

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GQL_HOST_HTTP,
  headers: {
    "x-hasura-admin-secret": process.env.REACT_APP_GQL_AUTH,
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_GQL_HOST_WS,
    connectionParams: async () => ({
      headers: {
        "x-hasura-admin-secret": process.env.REACT_APP_GQL_AUTH,
      },
    }),
  })
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      logErrorToCloudWatch(message, "auth-error");
    }
    );
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: errorLink.concat(splitLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

const MapApp = ({ userInfo }) => {
  const { isLoading } = false;

  const mapRef = React.useRef(null);

  if (userInfo.isLoading) {
    return (
      <div className="center">
        Loading Okta Information...
        <BeatLoader color="#169f2d" />
      </div>
    );
  }

  return (
    <ApolloProvider client={client}>
      <LoadingProvider>
        <AppProvider>
          <MapContext.Provider value={{ mapRef }}>
            <div className="App">
              {isLoading && <LoadingScreen />}
              <Suspense fallback={<div>Loading Map...</div>}>
                <MapParent userInfo={userInfo.userInfo} />
              </Suspense>
            </div>
          </MapContext.Provider>
        </AppProvider>
      </LoadingProvider>
    </ApolloProvider>
  );
};

MapApp.propTypes = {
  userInfo: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    userInfo: PropTypes.object,
  }).isRequired,
};

const AppWithUserInfo = () => {
  const userInfo = useUserProfile();
  return <MapApp userInfo={userInfo} />;
};

class App extends Component {
  state = {
    authError: false,
  };

  constructor(props) {
    super(props);
    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      try {
        props.history.replace(
          toRelativeUrl(originalUri || "/", window.location.origin)
        );
      } catch (err) {
        console.error("Error in Okta authentication:", err);
        this.setState({ authError: true });
        logErrorToCloudWatch(err, "auth-error");
      }
    };
  }

  render() {
    if (this.state.authError) {
      return <div>Authentication error. Please try again later.</div>;
    }

    return (
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <AppProvider>
            <Security
              oktaAuth={OKTA_CONFIG}
              restoreOriginalUri={this.restoreOriginalUri}
            >
              <SecureRoute path="/" component={AppWithUserInfo} />
              <Route path="/login/callback" component={LoginCallback} />
            </Security>
          </AppProvider>
        </ApolloProvider>
      </ErrorBoundary>
    );
  }
}
App.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

const AppWithRouterAccess = withRouter(App);

class RouterApp extends Component {
  render() {
    return (
      <Router>
        <ReduxProvider store={store}>
          <AppWithRouterAccess />
        </ReduxProvider>
      </Router>
    );
  }
}

export default RouterApp;