import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_NEW_AREA } from '../../graphql/mutations';
import { TIER_OPTIONS } from '../constants';
import { useIsUserInGroup } from '../../oktaUtils';
import { MAP_EDITOR_GROUPNAME } from '../../utils';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';


const AddNewArea = ({ userInfo }) => {
  const [newAreaName, setNewAreaName] = useState('');
  const [newAreaTier, setNewAreaTier] = useState(null);
  const [selectedSalesManager, setSelectedSalesManager] = useState(null);
  const [areaCreateSuccess, setAreaCreateSuccess] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const isUserInMapEditGroup = useIsUserInGroup(MAP_EDITOR_GROUPNAME, userInfo);

  const salesManagersData = useSelector(state => state.agents?.salesmanagers);


  const [addNewArea, { loading, error }] = useMutation(CREATE_NEW_AREA, {
    onCompleted: (data) => {
      const { id, name, tier } = data.insert_atm_areas_one;
      setAreaCreateSuccess(true);
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    addNewArea({
      variables: {
        areaName: newAreaName,
        tier: newAreaTier,
        salesManagerId: selectedSalesManager
      }
    });
    e.target.reset();
  };

  const clearResultMessage = () => {
    if (areaCreateSuccess || resultMessage) {
      setAreaCreateSuccess(false);
      setResultMessage('');
    }
  };

  useEffect(() => {
    if (error) setResultMessage('ERROR');
    if (loading) setResultMessage('Submitting...');
    if (areaCreateSuccess) setResultMessage(`New area created!`);
  }, [areaCreateSuccess, loading, error]);

  if (isUserInMapEditGroup) {
    return (
      <>
        <form className="new-area-form" onSubmit={handleSubmit}>
          <div className="new-area-form-label">Create New Area</div>
          <div className="new-area-form-wrapper">
            <input
              id="new-area-form-name"
              className="new-area-form-name"
              type='text'
              placeholder={'Enter Area Name'}
              onChange={(e) => {
                setNewAreaName(e.target.value);
                clearResultMessage();
              }}
            />
            <select
              className="new-area-form-tier"
              onChange={(e) => {
                setNewAreaTier(e.target.value);
                clearResultMessage();
              }}
            >
              <option default hidden>Tier</option>
              {TIER_OPTIONS.map(({ label, value }) => (
                <option
                  key={value}
                  value={value}
                >
                  {label}
                </option>
              ))}
            </select>
            {/* Sales Manager Selector */}
            <select
              defaultValue=""
              onChange={(e) => {
                setSelectedSalesManager(parseInt(e.target.value, 10));
                clearResultMessage();
              }}
            >
              <option value="" disabled>Select a sales manager</option>
              {(
                salesManagersData && salesManagersData?.map(manager => (
                  <option key={manager.id} value={manager.id}>{manager.name}</option>
                ))
              )}
            </select>
            <input
              className="new-area-form-btn"
              type='submit'
            />
          </div>
        </form>
        {
          resultMessage && <div className={
            cn('new-area-form-result', {
              error: error,
              loading: loading,
              success: areaCreateSuccess,
            })
          }>{resultMessage}</div>
        }
      </>
    )
  } else {
    return null;
  }
};

AddNewArea.propTypes = {
  userInfo: PropTypes.object
}

export default AddNewArea;
