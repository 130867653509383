import React, { useContext, useEffect } from "react";
import "./MapBehaviorToggle.css";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { useIsUserInGroup } from "../../oktaUtils";
import { MAP_EDITOR_GROUPNAME } from "../../utils";
import {
  NOTIFY_AGENT_COLD_START_MARKETS,
  NOTIFY_AGENT_ZERO_LISTINGS_MARKETS,
  NOTIFY_WILDLY_UNBALANCED_MARKETS,
} from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { setMapBehavior } from "../../redux/mapActions";
import { setMarketIdFilter } from "../../redux/marketActions";
import MapContext from "../MapParent/MapContext";

const MapBehaviorToggle = (userInfo) => {
  const dispatch = useDispatch();
  const mapRef = useContext(MapContext);

  const [
    executeWildQuery,
    { loading: wildLoading, error: wildError, data: wildData },
  ] = useLazyQuery(NOTIFY_WILDLY_UNBALANCED_MARKETS);
  const [
    executeColdQuery,
    { loading: coldLoading, error: coldError, data: coldData },
  ] = useLazyQuery(NOTIFY_AGENT_COLD_START_MARKETS);
  const [
    executeZeroQuery,
    { loading: zeroLoading, error: zeroError, data: zeroData },
  ] = useLazyQuery(NOTIFY_AGENT_ZERO_LISTINGS_MARKETS);

  const mapBehavior = useSelector((state) => state.map.mapBehavior);

  const wildList =
    wildData?.market_observer_latest_observations_wildly_unbalanced.map(
      (obj) => obj.market_atm_id
    );
  const coldList = coldData?.market_observer_latest_observations_cold_start.map(
    (obj) => obj.market_atm_id
  );
  const zeroList = zeroData?.market_observer_latest_observations_zero_leads.map(
    (obj) => obj.market_atm_id
  );

  // const isUserInMapEditGroup = false;
  const isUserInMapEditGroup = useIsUserInGroup(
    MAP_EDITOR_GROUPNAME,
    userInfo.userInfo
  );

  useEffect(() => {
    if (!isUserInMapEditGroup && mapBehavior === "edit") {
      dispatch(setMapBehavior("view"));
    }
  }, [isUserInMapEditGroup, mapBehavior, dispatch]);

  const handleChange = (event, newMapBehavior) => {
    if (!isUserInMapEditGroup && newMapBehavior !== "view") {
      dispatch(setMapBehavior("view"));
    } else {
      dispatch(setMapBehavior(newMapBehavior));
    }
    mapRef.current = null;

    if (newMapBehavior === "wild") {
      executeWildQuery();
      dispatch(setMarketIdFilter(wildList));
    } else if (newMapBehavior === "cold") {
      executeColdQuery();
      dispatch(setMarketIdFilter(coldList));
    } else if (newMapBehavior === "zero") {
      executeZeroQuery();
      dispatch(setMarketIdFilter(zeroList));
    } else {
      dispatch(setMarketIdFilter([]));
    }
  };

  return (
    <div className="map-behavior-toggle">
      <ToggleButtonGroup
        color="primary"
        style={{ background: "#4a454970" }}
        value={mapBehavior}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
      >
        {isUserInMapEditGroup && (
          <ToggleButton
            style={{ fontWeight: "bold", color: "white" }}
            value="edit"
          >
            Edit
          </ToggleButton>
        )}
        {!isUserInMapEditGroup && (
          <ToggleButton
            style={{ fontWeight: "bold", color: "white" }}
            value="view"
          >
            View
          </ToggleButton>
        )}
        <ToggleButton
          style={{ fontWeight: "bold", color: "white" }}
          value="wild"
        >
          Unbalanced ({wildList ? wildList.length : 0})
        </ToggleButton>
        <ToggleButton
          style={{ fontWeight: "bold", color: "white" }}
          value="zero"
        >
          Zero Leads ({zeroList ? zeroList.length : 0})
        </ToggleButton>
        <ToggleButton
          style={{ fontWeight: "bold", color: "white" }}
          value="cold"
        >
          Cold Start ({coldList ? coldList.length : 0})
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default MapBehaviorToggle;
