import { SELECT_ZIP, SET_OPEN_ZIPS, SET_ZIP_LIST } from './zipActions';

const initialState = {
    selectedZip: null,
    openZips: false,
    zipList: [],
};

const zipReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_ZIP:
            return {
                ...state,
                selectedZip: action.payload,
            };
        case SET_OPEN_ZIPS:
            return {
                ...state,
                openZips: action.payload,
            };
        case SET_ZIP_LIST:
            return {
                ...state,
                zipList: action.payload,
            };
        default:
            return state;
    }
};

export default zipReducer;