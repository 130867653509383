export const SET_CENTER = "SET_CENTER";
export const SET_ZOOM = "SET_ZOOM";
export const ADD_LAYER = "ADD_LAYER";
export const ADD_SOURCE = "ADD_SOURCE";
export const FLY_TO_ZIP = "FLY_TO_ZIP";
export const INIT_MAP = "INIT_MAP";
export const SET_MAP_BEHAVIOR = 'SET_MAP_BEHAVIOR';


export const setCenter = (center) => ({
  type: SET_CENTER,
  payload: center,
});

export const setZoom = (zoom) => ({
  type: SET_ZOOM,
  payload: zoom,
});

export const addLayer = (layer) => ({
  type: ADD_LAYER,
  payload: layer,
});

export const addSource = (sourceId, sourceData) => ({
  type: ADD_SOURCE,
  payload: { sourceId, sourceData },
});

export const flyToZip = (coordinates) => ({
  type: FLY_TO_ZIP,
  payload: coordinates,
});

export const initMap = (mapZoom, mapCenter) => ({
  type: INIT_MAP,
  payload: { mapZoom, mapCenter },
});

export const setMapBehavior = (behavior) => ({
  type: SET_MAP_BEHAVIOR,
  payload: behavior,
});