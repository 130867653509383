import { ADD_LAYER, ADD_SOURCE, FLY_TO_ZIP, INIT_MAP, SET_CENTER, SET_ZOOM, SET_MAP_BEHAVIOR} from "./mapActions";

const initialState = {
  mapCenter: [-84.725034, 33.738045],
  style: "mapbox://styles/mapbox/light-v11",
  mapZoom: 8,
  layers: [],
  sources: [],
  mapBehavior: null,
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_MAP:
      return {
        ...state,
        mapCenter: action.payload.mapCenter,
        mapZoom: action.payload.mapZoom,
      };
    case SET_CENTER:
      return { ...state, mapCenter: action.payload };
    case SET_ZOOM:
      return { ...state, mapZoom: action.payload };
    case ADD_LAYER:
      return { ...state, layers: [...state.layers, action.payload] };
    case ADD_SOURCE:
      return {
        ...state,
        sources: {
          ...state.sources,
          [action.payload.sourceId]: action.payload.sourceData,
        },
      };
    case FLY_TO_ZIP:
      return { ...state, mapCenter: action.payload };
    case SET_MAP_BEHAVIOR:
      return { ...state, mapBehavior: action.payload };
    default:
      return state;
  }
};

export default mapReducer;
