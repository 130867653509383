import { gql } from "@apollo/client";

export const SUBSCRIBE_AGENTS = gql`
  subscription MyQuery($marketId: Int) {
    atm_agents(
      where: {
        _not: { agent_market_availabilities: { market_id: { _eq: $marketId } } }
        eligible: { _eq: true }
      }
    ) {
      id
      name
      email
      salesforce_id
      program
    }
  }
`;

export const SUBSCRIBE_MARKET_DETAILS = gql`
  subscription MarketDetails($id: Int!) {
    atm_markets_by_pk(id: $id) {
      name
      zip_codes {
        id
        name
      }
      agent_market_availabilities(
        order_by: { agent: { name: asc } }
        where: { agent: { eligible: { _eq: true } } }
      ) {
        agent {
          id
          name
          program
        }
        available_monday
        available_tuesday
        available_wednesday
        available_thursday
        available_friday
        available_saturday
        available_sunday
        id
      }
    }
  }
`;

export const SUBSCRIBE_LATEST_MARKET_DISTRIBUTION = gql`
  subscription LatestObservation($id: bigint!) {
    market_observer_latest_observations(
      where: { market_atm_id: { _eq: $id } }
    ) {
      agent_name
      market
      market_atm_id
      market_experimental_features
      agent_score
      agent_lead_count
      distribution_imbalance
      current_distribution
      desired_distribution
    }
  }
`;

export const NOTIFY_NEW_AREAS = gql`
  subscription AreasSubscription {
    atm_areas(order_by: { name: asc }) {
      id
      name
      tier
      sales_manager_id
      markets(order_by: { name: asc }) {
        id
        name
        parent_area_id
        friendly_name
        color
        zip_codes_aggregate {
          aggregate {
            count
          }
        }
        agent_market_availabilities_aggregate {
          aggregate {
            count
          }
        }
        zip_codes(limit: 1) {
          id
          geom
          name
        }
      }
    }
  }
`;



// export const NOTIFY_ZIP_MARKET_ASSIGNMENTS = gql`
//   query ZipMarketAssignments($district_polygon: geometry) {
//     atm_zip_codes(where: { geom: { _st_intersects: $district_polygon } }) {
//       id
//       geom
//       name
//       market {
//         id
//         name
//         color
//         parent_area_id
//         friendly_name
//       }
//     }
//   }
// `;

export const NOTIFY_ZIP_MARKET_ASSIGNMENTS = gql`
  query ZipMarketAssignments($district_polygon: geometry) {
    atm_materialized_zip_market(where: { geom: { _st_intersects: $district_polygon } }) {
      zip_id
      geom
      zip_name
      market_id
      market_name
      color
      parent_area_id
      friendly_name
    }
  }
`;

export const NOTIFY_AGENT_ASSIGNMENTS = gql`
  subscription AgentAssignments($name: String) {
    atm_agents(where: { eligible: { _eq: true }, name: { _ilike: $name } }) {
      id
      name
      program
      agent_market_availabilities {
        market {
          id
          name
          parent_area_id
          friendly_name
          zip_codes {
            id
            geom
            name
          }
        }
      }
    }
  }
`;

export const NOTIFY_ZIP_MARKETS = gql`
  subscription AgentAssignments($name: String) {
    atm_zip_codes(order_by: { name: asc }, where: { name: { _ilike: $name } }) {
      id
      name
      market_id
      geom
      market {
        id
        name
        parent_area_id
        friendly_name
      }
    }
  }
`;
