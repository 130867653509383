import React, { useEffect } from "react";
import { useSubscription, useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  NOTIFY_NEW_AREAS
} from "./graphql/subscriptions";
import { GET_ALL_SALES_MANAGERS } from "./graphql/queries";
import { setAreas, setAreaLoading } from "./redux/areaActions";
import {
  GET_SALESMANAGERS_SUCCESS,
  GET_SALESMANAGERS_REQUEST,
  GET_SALESMANAGERS_FAILURE,
} from "./redux/agentActions";

const AppProvider = ({ children }) => {
  const dispatch = useDispatch();

  const { loading: areasLoading, error, data: areaData } = useSubscription(NOTIFY_NEW_AREAS);

  useEffect(() => {
    if (areasLoading) {
      dispatch(setAreaLoading(true));
    }
    if (areaData && areaData.atm_areas) {
      dispatch(setAreas(areaData.atm_areas));
      dispatch(setAreaLoading(false));
    }
  }, [areaData, dispatch, areasLoading]);

  const {
    loading: loadingSalesManagers,
    data: salesManagersData,
    error: salesManagersError,
  } = useQuery(GET_ALL_SALES_MANAGERS);

  useEffect(() => {
    if (loadingSalesManagers) {
      dispatch({ type: GET_SALESMANAGERS_REQUEST });
    } else if (salesManagersData) {
      dispatch({
        type: GET_SALESMANAGERS_SUCCESS,
        payload: salesManagersData.atm_sales_manager,
      });
    } else if (salesManagersError) {
      dispatch({
        type: GET_SALESMANAGERS_FAILURE,
        payload: salesManagersError.message,
      });
    }
  }, [loadingSalesManagers, salesManagersData, salesManagersError, dispatch]);

  return <>{children}</>;
};

AppProvider.propTypes = {
  children: PropTypes.node,
};

export default AppProvider;
