// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.market-panel {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
}

.market-color-swatch {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  cursor: pointer;
}

.market-name {
  margin: 0 16px;
  cursor: pointer;
}

.market-name:hover, .market-name.selected {
  color: greenyellow;
}

.color-picker-popover {
  position: absolute;
  z-index: 200;
  top: 40px;
  left: -8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MarketList/MarketList.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,sBAAsB;EACtB,oCAAoC;EACpC,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,UAAU;AACZ","sourcesContent":[".market-panel {\n  display: flex;\n  align-items: center;\n  margin-bottom: 16px;\n  position: relative;\n}\n\n.market-color-swatch {\n  height: 20px;\n  width: 20px;\n  border-radius: 50%;\n  border: 3px solid #fff;\n  box-shadow: 0 0 0 1px rgba(0,0,0,.1);\n  cursor: pointer;\n}\n\n.market-name {\n  margin: 0 16px;\n  cursor: pointer;\n}\n\n.market-name:hover, .market-name.selected {\n  color: greenyellow;\n}\n\n.color-picker-popover {\n  position: absolute;\n  z-index: 200;\n  top: 40px;\n  left: -8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
