import React, { useEffect, useRef, useContext } from "react";
import mapboxgl from "mapbox-gl";
import PropTypes from 'prop-types';
import MapContext from "../MapParent/MapContext";

export const Popup = ({ children, lngLat, onClose }) => {

  const mapRef = useContext(MapContext);


  const popupRef = useRef();

  useEffect(() => {
    const popup = new mapboxgl.Popup({})
      .setLngLat(lngLat)
      .setDOMContent(popupRef.current)
      .addTo(mapRef?.current);

    popup.on("close", onClose);

    return popup.remove;
  }, [children, lngLat, mapRef, onClose]);

  return (
    <div style={{ display: "none" }}>
      <div ref={popupRef}>{children}</div>
    </div>
  );
};
Popup.propTypes = {
  children: PropTypes.node.isRequired,
  lngLat: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
}

export default Popup;
