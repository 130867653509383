import React, { useMemo, useContext, useCallback } from "react";
import cn from "classnames";
import "./MarketDetails.css";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { selectZip } from "../../redux/zipActions";
import { selectMarket } from "../../redux/marketActions";
import { flyToZip } from "../Map/mapUtility";
import MapContext from "../MapParent/MapContext";

const ZipSelect = ({ zipcodes, selectedMarket }) => {
  const dispatch = useDispatch();
  const mapRef = useContext(MapContext);

  const selectedZip = useSelector((state) => state.zips.selectedZip);

  const handleClick = (zipCode) => {
    dispatch(selectZip(zipCode));
    if (zipCode.market) {
      dispatch(selectMarket(zipCode.market));
    } else {
      if (zipCode.market_id) {
        dispatch(selectMarket(zipCode.market_id));
      }
    }
    flyToZip(mapRef, zipCode);
  };

  const zipcodesList = useMemo(
    () =>
      zipcodes?.map((zipcode) => (
        <li
          key={"market-detail-zip-" + zipcode.id}
          className="zipcode-list-item"
        >
          <a
            className={cn(
              "zipcode",
              selectedZip?.id === zipcode.id ? "selected" : ""
            )}
            onClick={() => handleClick(zipcode)}
          >
            {zipcode.name}
          </a>
        </li>
      )),
    [zipcodes, selectedZip, handleClick]
  );

  return (
    <div>
      <ul className="market-details-zipcodes-list">{zipcodesList}</ul>
    </div>
  );
};
ZipSelect.propTypes = {
  zipcodes: PropTypes.array.isRequired,
  selectedMarket: PropTypes.object,
};

export default ZipSelect;
