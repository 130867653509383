import React from "react";
import AreaList from "../components/AreaList";
import MarketDetailsContainer from "./MarketDetailsContainer";
import MarketCharts from "../components/MarketCharts";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import "../components/AreaList/AreaList.css";

const AreaListContainer = ({ userInfo }) => {
  const selectedMarket = useSelector((state) => state.markets?.selectedMarket);
  const areaList = useSelector((state) => state.areas?.areaList);
  const areaListLoading = useSelector((state) => state.areas?.areaLoading);

  const mapBehavior = useSelector((state) => state.map?.mapBehavior);
  const marketIdFilter = useSelector((state) => state.markets?.marketIdFilter);

  let filteredAreas = [];
  if (marketIdFilter?.length > 0) {
    areaList.map((area) => {
      let marketsToInclude = [];
      for (const market of area.markets) {
        if (marketIdFilter.includes(market.id)) {
          marketsToInclude.push(market);
        }
      }

      if (marketsToInclude?.length > 0) {
        area.markets = marketsToInclude;
        filteredAreas.push(area);
      }
    });
  }

  return (
    <div className="area-list-container">
      {areaListLoading && (
        <div className="sidebar-container">
          <div className={`area-list-panel`}>
            <p>Loading Area List...</p>
          </div>
        </div>
      )}
      {!areaListLoading && (
        <>
          <AreaList
            userInfo={userInfo}
            areas={filteredAreas?.length === 0 ? areaList : filteredAreas}
            key={"area-list-data"}
          />
          {selectedMarket && (
            <MarketDetailsContainer
              userInfo={userInfo}
              market={selectedMarket}
            />
          )}
          {mapBehavior !== "edit" && selectedMarket && (
            <MarketCharts market={selectedMarket} />
          )}
        </>
      )}
    </div>
  );
};

AreaListContainer.propTypes = {
  userInfo: PropTypes.object,
};

export default AreaListContainer;
