import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_NEW_MARKET_VIA_AREA } from '../../graphql/mutations';
import PropTypes from 'prop-types';

function AddNewMarket({area}) {
    const [newMarketName, setNewMarketName] = useState('');
    const [newMarketFriendlyName, setNewMarketFriendlyName] = useState('');
    const [addMarket, {loading, error}] = useMutation(CREATE_NEW_MARKET_VIA_AREA, {onCompleted: (data) => setNewMarketName('')});

    function handleSubmit(event) {
      event.preventDefault();
      if (!newMarketName.trim() || !newMarketFriendlyName.trim()) {
          alert('Both Market Name and Friendly Name are required.');
          return; 
      }
      addMarket({
          variables: {
              areaName: area.name,
              tier: area.tier,
              marketName: newMarketName,
              friendlyName: newMarketFriendlyName
          }
      })
    }

    if (loading) return 'Submitting...';
    if (error) return `Submission error! ${error.message}`;
    return (
      <form onSubmit={handleSubmit} className="new-market-form">
        <label>
          <input
            type="text"
            placeholder={'Create New Market'}
            value={newMarketName}
            onChange={(e) => setNewMarketName(e.target.value)}
          />
        </label>
        <label>
          <input
            type="text"
            placeholder={'Friendly Name'}
            value={newMarketFriendlyName}
            onChange={(e) => setNewMarketFriendlyName(e.target.value)}
          />
        </label>
        <input type="submit"/>
      </form>
    );
}

AddNewMarket.propTypes = {
    area: PropTypes.object.isRequired
} 

export default AddNewMarket;
