import React from 'react';
import { useMutation } from '@apollo/client';
import { ReactComponent as DeleteIcon } from '../../assets/trash-can.svg';
import { DELETE_AREA, DELETE_MARKET } from '../../graphql/mutations';
import { MAP_EDITOR_GROUPNAME } from '../../utils';
import { getErrorMessage } from '../../utility/ErrorMessages';
import PropTypes from 'prop-types';

const setMutationType = (type) => {
  switch(type) {
    case 'area':
      return DELETE_AREA;
    case 'market':
      return DELETE_MARKET;
    default:
      throw new Error('Unknown mutation type');
  }
};

const DeleteItem = ({id, type, userInfo}) => {
    const [deleteItem, {loading}] = useMutation(setMutationType(type), { onError: showWarning });

    function handleClick(event) {
      event.preventDefault();
      if (window.confirm(`Are you sure you want to delete this ${type}?`)) {
        deleteItem({
          variables: {
            id: id
          }
        })
      }
    }

    function showWarning(event) {
        window.alert(getErrorMessage(event?.message));
    }
    

    if (loading) return 'Submitting...';

    

    if (userInfo && userInfo?.groups?.includes(MAP_EDITOR_GROUPNAME)) {
      return (
        <button
          onClick={handleClick}
          className="btn-delete btn-delete-item"
        >
          <DeleteIcon />
        </button>
      );
    } else {
      return null;
    }
}

DeleteItem.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  userInfo: PropTypes.object,
};

export default DeleteItem;
