import React, { useMemo, useState } from "react";
import Area from "./Area";
import SearchBar from "./SearchBar";
import AddNewArea from "./AddNewArea";
import "./AreaList.css";
import AgentList from "../AgentList/AgentList";
import ZipList from "../ZipList/ZipList";
import PropTypes from "prop-types";

const AreaList = ({ areas, userInfo }) => {
  const areaList = useMemo(
    () =>
      areas?.map((area) => (
        <Area userInfo={userInfo} key={area.id} area={area} />
      )),
    [areas, userInfo]
  );

  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [filteredAreas, setFilteredAreas] = useState(areaList);
  const [isFound, setIsFound] = useState(true);
  const [agentIsFound, setAgentIsFound] = useState(true);
  const [zipIsFound, setZipIsFound] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false); // New loading state

  const handleSearchSubmit = (searchQuery) => {
    setIsLoading(true); // Start loading
    setSearchQuery(searchQuery);
    const filtered = areaList?.filter((area) => {
      const areaData = area.props.area;
      if (areaData.name.toLowerCase().includes(searchQuery)) return true;

      if (areaData.markets) {
        for (const market of areaData.markets) {
          if (market.name.toLowerCase().includes(searchQuery)) return true;
        }
      }
      return false;
    });

    if (filtered?.length > 0) {
      setIsFound(true);
      setFilteredAreas(filtered);
    } else {
      setIsFound(false);
      setFilteredAreas(areaList);
    }
    setIsLoading(false); // End loading
  };

  const handleZipDataEmpty = () => {
    setZipIsFound(false);
  };

  const handleZipDataFound = () => {
    setZipIsFound(true);
  };

  const handleAgentsEmpty = () => {
    setAgentIsFound(false);
  };

  const handleAgentDataFound = () => {
    setAgentIsFound(true);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <div className="sidebar-container">
      <div className={`area-list-panel ${isSidebarVisible ? "" : "hidden"}`}>
        <div>
          <button
            className={`${isSidebarVisible ? "hide-button" : "show-button"}`}
            onClick={toggleSidebar}
          >
            {isSidebarVisible ? "Hide" : "Show"} Sidebar
          </button>
        </div>
        <div>
          {isSidebarVisible && (
            <>
              <SearchBar handleSubmit={handleSearchSubmit} />
              <AddNewArea userInfo={userInfo} />
            </>
          )}
        </div>

        {isLoading && <div>Area Searching...</div>} {/* Loader display */}
        {isSidebarVisible && (
          <div className="area-list-data">
            {isFound ? filteredAreas : ""}
            {searchQuery !== "" && (
              <AgentList
                userInfo={userInfo}
                searchQuery={searchQuery}
                onDataFound={handleAgentDataFound}
                onDataEmpty={handleAgentsEmpty}
              />
            )}
            {searchQuery !== "" && (
              <ZipList
                searchQuery={searchQuery}
                onDataFound={handleZipDataFound}
                onDataEmpty={handleZipDataEmpty}
              />
            )}
            {!isFound && !zipIsFound && !agentIsFound && (
              <div className="no-match-msg">
                Sorry, nothing matched your query.
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

AreaList.propTypes = {
  areas: PropTypes.array,
  userInfo: PropTypes.object,
};

export default AreaList;
