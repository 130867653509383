import React from 'react';
import {useMutation} from '@apollo/client';
import { ReactComponent as DeleteIcon } from '../../../assets/trash-can.svg';
import { DELETE_AGENT_FROM_MARKET } from '../../../graphql/mutations';
import { useIsUserInGroup } from '../../../oktaUtils';
import { MAP_EDITOR_GROUPNAME } from '../../../utils';
import PropTypes from 'prop-types';

const DeleteAgentFromMarket = ({agent_market_availability, userInfo}) => {
    const [deleteAgentFromMarket, {loading, error}] = useMutation(DELETE_AGENT_FROM_MARKET);
    const isUserInMapEditGroup = useIsUserInGroup(MAP_EDITOR_GROUPNAME, userInfo);

    function handleClick(event) {
      event.preventDefault();
        if (window.confirm('Are you sure you want to delete this agent from this market?')) {
          deleteAgentFromMarket({
            variables: {
              id: agent_market_availability.id
            }
        })
      }
    }

    if (loading) return 'Submitting...';
    if (error) return `Submission error! ${error.message}`;

    if (isUserInMapEditGroup) {
      return (
        <button
          onClick={handleClick}
          className="btn-delete btn-delete-agent"
        >
          <DeleteIcon />
        </button>
      );
    } else {
      return null;
    }
}

DeleteAgentFromMarket.propTypes = {
  agent_market_availability: PropTypes.object.isRequired,
  userInfo: PropTypes.object
};

export default DeleteAgentFromMarket;
