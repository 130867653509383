import React, { useMemo, useRef } from "react";
import "../AreaList/AreaList.css";
import Market from "../MarketList/Market";
import PropTypes from 'prop-types';

const Agent = ({ agent, userInfo }) => {

  const childRef = useRef();

  const marketList = useMemo(
    () =>
      agent.agent_market_availabilities.map((marketDetail, index) => (
        <Market
          userInfo={userInfo}
          ref={childRef}
          market={marketDetail.market}
          key={marketDetail.market.id}
        />
      )),
    [agent, userInfo]
  );

  const handleAgentNameClick = () => {
    if (marketList?.length > 0) {
      const firstChild = document.getElementById('market-' + marketList[0].key);
      firstChild.childNodes[1].click()
    }
  };

  return (
    <div
      className="area-panel"
      id={"agent-" + agent.id}
    >
      <div className="area-panel-header-container">
        <h4 className="area-panel-header" onClick={handleAgentNameClick}>{agent.name}</h4>
      </div>
      <div>{marketList ? marketList : ""}</div>
    </div>
  );
};

Agent.propTypes = {
  agent: PropTypes.object.isRequired,
  userInfo: PropTypes.object
}


export default Agent;
