import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from "@apollo/client";
import { UPDATE_MARKET_PARENT_AREA } from '../../graphql/mutations';
import cn from  'classnames';
import { useIsUserInGroup } from '../../oktaUtils';
import { MAP_EDITOR_GROUPNAME } from '../../utils';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const AreaSelect = ({ marketId, marketParentAreaId, userInfo }) => {

  const areaList = useSelector(state => state.areas?.areaList);

  const [selectedAreaId, setSelectedAreaId] = useState(marketParentAreaId);
  const [isAreaUpdated, setIsAreaUpdated] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const isUserInMapEditGroup = useIsUserInGroup(MAP_EDITOR_GROUPNAME, userInfo);

  const [updateMarketParentArea, {loading, error}] = useMutation(UPDATE_MARKET_PARENT_AREA, {
    onCompleted: (data) => {
      setIsAreaUpdated(true);
    }
  });

  const areaSelectOptions = useMemo(() => areaList.map(area => (
    <option
      key={area.id}
      value={area.id}
    >
      {area.name}
    </option>
  )), [areaList]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateMarketParentArea({
      variables: {
        marketId: marketId,
        areaId: selectedAreaId,
      }
    });
  };

  const clearResultMessage = () => {
    if (isAreaUpdated || resultMessage) {
      setIsAreaUpdated(false);
      setResultMessage('');
    }
  };

  useEffect(() => {
    if (error) setResultMessage('ERROR');
    if (loading) setResultMessage('Submitting...');
    if (isAreaUpdated) setResultMessage(`Market parent area has been updated!`);
  }, [isAreaUpdated, loading, error]);

  if (isUserInMapEditGroup) {
    return (
      <>
        <form className="assign-market-area" onSubmit={handleSubmit}>
          <select
            className="assign-market-area-select"
            defaultValue=''
            onChange={(e) => {
              setSelectedAreaId(e.target.value);
              clearResultMessage();
            }}
          >
            <option default hidden>Assign Market to New Area</option>
            { areaSelectOptions }
          </select>
          <input type="submit" className="assign-market-area-btn"/>
        </form>
        {
          resultMessage && <div className={
            cn('assign-market-area-result', {
              error: error,
              loading: loading,
              success: isAreaUpdated,
            })
          }>{resultMessage}</div>
        }
      </>
    )
  } else {
    return null;
  }
};
AreaSelect.propTypes = {
  marketId: PropTypes.number,
  marketParentAreaId: PropTypes.number,
  userInfo: PropTypes.object
}

export default AreaSelect;
