import React from 'react';
import styled from 'styled-components';
import BeatLoader from "react-spinners/BeatLoader";
const Overlay = styled.div`
  position: fixed; /* Position relative to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of other content */
`;

const LoadingText = styled.div`
  color: white;
  font-size: 2em;
`;

const LoadingScreen = () => (
  <Overlay>
    <LoadingText> Gathering Zip Information <BeatLoader style={{textAlign: 'center'}} color="#169f2d"/></LoadingText>
  </Overlay>
);

export default LoadingScreen;
