import {
  useEffect,
  useCallback,
} from 'react';

const useOnClickOutside = (
  ref,
  callback,
  enable,
) => {
  const clickListener = useCallback((e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback(e);
    }
  }, [ref, callback]);

  useEffect(() => {
    if (enable) {
      document.addEventListener('click', clickListener);
    } else {
      document.removeEventListener('click', clickListener);
    }
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, [enable, clickListener]);
};

export default useOnClickOutside;
