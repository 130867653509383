import React, { useMemo } from 'react';
import Market from './Market';
import './MarketList.css';
import PropTypes from 'prop-types';

const MarketList = ({
  areaId,
  markets,
  userInfo,
}) => {

  const marketList = useMemo(() => markets.map(market => (
    <Market
      userInfo={userInfo}
      key={'area-' + areaId + '-market-' + market.id}
      market={market}
    />
  )), [markets]);

  return (
    <div className="market-list-panel">
      {marketList}
    </div>
  );
};

MarketList.propTypes = {
  areaId: PropTypes.number,
  markets: PropTypes.array.isRequired,
  userInfo: PropTypes.object
}

export default MarketList;
