import React from "react";
import {Chart} from "react-google-charts";
import PropTypes from 'prop-types';


const PieChart = (props) => {
    let dataOld = [
        ["Agent", "Distribution"],
    ];

    let dataNew = [
        ["Agent", "Distribution"],
    ];
    if (props) {
        for (const [ck, cv] of Object.entries(props.marketDistributionData.market_observer_latest_observations)) {
            let agentName = cv.agent_name+': '+cv.agent_lead_count+' leads, score: ' + Math.round(cv.agent_score);
            console.log(agentName, cv)
            dataNew.push([agentName, cv.current_distribution])
            dataOld.push([agentName, cv.desired_distribution])
        }
    } else {
        return
    }

    const diffdata = {
        old: dataOld,
        new: dataNew,
    };

    const options = {
        pieSliceText: "none",
    };

    return (
        <Chart
            chartType="PieChart"
            width="600px"
            height="380px"
            diffdata={diffdata}
            options={options}
        />
    );
}

PieChart.propTypes = {
    marketDistributionData: PropTypes.object.isRequired
}

export default PieChart;