import { useSubscription } from '@apollo/client';
import React from 'react';
import MarketDetails from '../components/MarketDetails';
import { SUBSCRIBE_MARKET_DETAILS } from '../graphql/subscriptions';
import PropTypes from 'prop-types';

const MarketDetailsContainer = ({ market , openZips, userInfo }) => {
  const {loading, error, data} = useSubscription(SUBSCRIBE_MARKET_DETAILS, {variables: {id: market.id}});

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>Error</span>;
  }

  const marketDetails = data?.atm_markets_by_pk;

  return (
    <MarketDetails
      userInfo={userInfo}
      market={market}
      marketDetails={marketDetails}
    />
  );
};

MarketDetailsContainer.propTypes = {
  market: PropTypes.object,
  openZips: PropTypes.array,
  userInfo: PropTypes.object
};

export default MarketDetailsContainer;
