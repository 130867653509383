import React, { useEffect, useState} from 'react';
import { useSubscription } from '@apollo/client';
import Agent from './Agent';
import '../AreaList/AreaList.css';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { NOTIFY_AGENT_ASSIGNMENTS } from '../../graphql/subscriptions';
import { fetchAgentAssignmentsRequest, fetchAgentAssignmentsSuccess, fetchAgentAssignmentsFailure } from '../../redux/agentActions';

const AgentList = ({ searchQuery , onDataEmpty , onDataFound, userInfo }) => {
  const dispatch = useDispatch();
  const agents = useSelector(state => state.agents?.agents);

  const [ isLoading, setIsLoading ] = useState(false);

  useSubscription(NOTIFY_AGENT_ASSIGNMENTS, {
    variables: { name: '%' + searchQuery + '%' },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data) {
        setIsLoading(false);
        dispatch(fetchAgentAssignmentsSuccess(subscriptionData.data.atm_agents));
        if (subscriptionData.data.atm_agents.length === 0) {
          onDataEmpty();
        } else {
          onDataFound();
        }
      }
    },
    onError: (error) => {
      setIsLoading(false);
      dispatch(fetchAgentAssignmentsFailure(error));
    },
    onStart: () => setIsLoading(true),
  });

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchAgentAssignmentsRequest(searchQuery));
  }, [dispatch, searchQuery]);

  const agentList = agents.map(agent => (
    <Agent userInfo={userInfo} agent={agent} key={agent.id}/>
  ));

  return (
    <div>
      { isLoading && <div>Agent Searching...</div>}
      { agentList?.length > 0 &&
        <h3 className="agent-list-header">Agent List</h3>
      }
      <div>
        { 
          agentList ? agentList :  ''
        }
      </div>
    </div>
  );
  
};

AgentList.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  onDataEmpty: PropTypes.func.isRequired,
  onDataFound: PropTypes.func.isRequired,
  userInfo: PropTypes.object,
  isLoading: PropTypes.bool
}

export default AgentList;
