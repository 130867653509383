import React, {
  useCallback,
  useEffect,
  useContext,
  useRef,
  useState,
} from "react";
import {
  INITIAL_VIEW_STATE,
  MAP_STYLE,
  VECTOR_SERVER_TEST,
  MARKET_SHAPES,
  COMMUNITY_POINTS,
  MAP_BOUNDS,
} from "../../mapbox-config";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line
import "mapbox-gl/dist/mapbox-gl.css";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import Popup from "./Popup";
import { makeVar, useReactiveVar, useQuery } from "@apollo/client";
import { NOTIFY_ZIP_MARKET_ASSIGNMENTS } from "../../graphql/subscriptions";
import { fetchZip } from "./mapboxApi";
import { ACCESS_TOKEN } from "./mapboxConstants";
import { ASSIGN_ZIP_TO_MARKET } from "../../graphql/mutations";
import { MAP_EDITOR_GROUPNAME } from "../../utils";
import { useSelector } from "react-redux";
import MapContext from "../MapParent/MapContext";
import PropTypes from "prop-types";

const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  min-width: 600px;
`;

const viewableBBox = makeVar(MAP_BOUNDS);

const Map = ({ userInfo, onEventTrigger }) => {
  const [content, setContent] = useState([]);
  const [popupLngLat, setPopupLngLat] = useState(null);

  const selectedMarket = useSelector((state) => state.markets?.selectedMarket);
  const selectedZip = useSelector((state) => state.zips?.selectedZip);
  
  const mapRef = useContext(MapContext);
  const mapContainer = useRef(null);

  const rvarbbox = useReactiveVar(viewableBBox);
  const [bbox, setBBox] = useState(rvarbbox); // Initialize with some default bounding box
  const [assignMarket] = useMutation(ASSIGN_ZIP_TO_MARKET);

  const {
    data: initData,
    loading: dataLoading,
    error: onError,
    refetch,
  } = useQuery(NOTIFY_ZIP_MARKET_ASSIGNMENTS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      district_polygon: bbox,
    },
  });

  useEffect(() => {
    if (initData && mapRef?.current?.getZoom() > 8) {
      const subFeatureCollection = {
        type: "FeatureCollection",
        features: initData.atm_materialized_zip_market.map((feature) => ({
          type: "Feature",
          properties: {
            id: feature.id,
            name: feature.zip_name,
            market: feature.market_name,
            market_id: feature?.market_id ?? null,
            color: feature?.color ?? "red",
            parent_area_id: feature?.parent_area_id ?? null,
          },
          geometry: feature.geom,
        })),
      };
      if (mapRef?.current) {
        mapRef?.current
          .getSource("assigned_zips")
          .setData(subFeatureCollection);
      }
    }
  }, [initData]);

  if (onError) {
    console.log("Error!!! " + onError);
  }
  function onPopupClose() {
    setPopupLngLat(null);
  }

  const doRefetchWithNewVariables = (map, newBbox) => {
    if (map.getZoom() > 8) {
      refetch({ district_polygon: newBbox });
    }
  };

  const featureCollection = {
    type: "FeatureCollection",
    features: [],
  };

  const onCircleMapClick = useCallback((e) => {
    let map = e.target;

    const coordinates = e.features[0].geometry.coordinates.slice(",");
    const communityName = e.features[0].properties.name;
    const propertyCount = e.features[0].properties.property_count;
    const popupContent =
      '<div class="map-community-popup-info">' +
      "<div><strong>" +
      communityName +
      "</strong></div>" +
      "<div>Property Count: " +
      propertyCount +
      "</div>" +
      "</div>";

    new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(popupContent)
      .addTo(map);
  }, []);

  let hoveredCommunity = null;

  useEffect(() => {
    mapboxgl.accessToken = ACCESS_TOKEN;

    const initializeMap = ({ mapContainer }) => {
      let map = new mapboxgl.Map({
        container: mapContainer.current,
        style: MAP_STYLE,
        center: [INITIAL_VIEW_STATE.longitude, INITIAL_VIEW_STATE.latitude],
        zoom: INITIAL_VIEW_STATE.zoom,
      });

      map.on("load", () => {
        // setLoading(false);
        onEventTrigger();
        loadMapSourcesAndLAyers(map);
        mapRef.current = map;

        // show community popup if circle is clicked
        map.on("click", COMMUNITY_POINTS.id, onCircleMapClick);

        map.on("mouseenter", COMMUNITY_POINTS.id, handleMouseEnter);

        map.on("mouseleave", COMMUNITY_POINTS.id, mouseout);

        map.on("moveend", () => {
          const newVar = {
            type: "Polygon",
            coordinates: [[]],
            crs: {
              type: "name",
              properties: {
                name: "EPSG:4326",
              },
            },
          };
          const top = map?.getBounds()._ne.lat;
          const bottom = map?.getBounds()._sw.lat;
          const left = map?.getBounds()._sw.lng;
          const right = map?.getBounds()._ne.lng;
          newVar.coordinates[0].push([left, top]); //top left
          newVar.coordinates[0].push([right, top]); //top right
          newVar.coordinates[0].push([right, bottom]); //bottom right
          newVar.coordinates[0].push([left, bottom]); //bottom left
          newVar.coordinates[0].push([left, top]); //top left

          viewableBBox(newVar);
          setBBox(newVar);
          doRefetchWithNewVariables(map, bbox);
          
        });
      });
    };

    if (!mapRef.current) initializeMap({ mapContainer });

    const flyToZip = async () => {
      if (selectedZip && selectedMarket) {
        const zip = await fetchZip(selectedZip.name);
        if (zip.body.features.length === 0) {
          window.alert(
            "This zip is not available in the map " + selectedZip.name
          );
          // setLoading(false);
        } else {
          const zipCenterCoord = zip.body.features[0].center;
          mapRef?.current?.flyTo({
            center: zipCenterCoord,
            essential: true,
            zoom: 8,
          });
          if (mapRef?.current?.getSource("zip-code-source")) {
            // If source already exists, update its data
            mapRef?.current
              ?.getSource("zip-code-source")
              .setData(
                selectedZip.geom !== undefined
                  ? selectedZip.geom
                  : zip.body.features[0].geometry
              );
          } else {
            // If source doesn't exist, add a new source and layer
            mapRef?.current?.addSource("zip-code-source", {
              type: "geojson",
              data:
                selectedZip.geom !== undefined
                  ? selectedZip.geom
                  : zip.body.features[0].geometry,
            });

            mapRef?.current?.addLayer({
              id: "zip-code-fill",
              type: "fill",
              source: "zip-code-source",
              paint: {
                "fill-color": "red", // Set the fill color as desired
                "fill-opacity": 0.5,
              },
            });
          }

          const marketName =
            selectedZip?.market?.name !== undefined
              ? selectedZip.market.name
              : selectedMarket?.name;
          const popupContent =
            '<div class="map-community-popup-info">' +
            "<div><strong>" +
            selectedZip?.name +
            "</strong></div>" +
            "<li><strong>" +
            "Current Market: </strong>" +
            marketName +
            "</li></div>";

          const divElement = document.createElement("div");
          divElement.innerHTML = popupContent;
          const assignBtn = document.createElement("div");
          if (
            userInfo &&
            userInfo.groups &&
            userInfo.groups.includes(
              MAP_EDITOR_GROUPNAME && selectedMarket.name !== marketName
            )
          ) {
            assignBtn.innerHTML =
              `<button class="btn" > Add to ` + marketName + `</button>`;
          }
          divElement.appendChild(assignBtn);
          assignBtn.addEventListener("click", (e) => {
            assignMarket({
              variables: {
                zipName: selectedZip.name,
                market: selectedZip.market
                  ? selectedZip.market
                  : selectedMarket,
              },
            });
          });

          new mapboxgl.Popup()
            .setLngLat(zipCenterCoord)
            .setDOMContent(divElement)
            .addTo(mapRef?.current);
        }
      }
    };
    flyToZip();

    return () => {
      if (mapRef) {
        mapRef?.current?.off("click");
      }
    };
  }, [mapRef, selectedMarket, selectedZip]);

  const mouseover = useCallback(
    (feature, mapRef) => {
      hoveredCommunity = feature;
      if (mapRef && mapRef?.current) {
        mapRef.current.getCanvas().style.cursor = "pointer";
        if (hoveredCommunity) {
          const { source, sourceLayer } = COMMUNITY_POINTS;
          mapRef?.current?.setFeatureState(
            {
              source,
              sourceLayer,
              id: hoveredCommunity.id,
            },
            {
              hover: true,
            }
          );
        }
      }
    },
    [mapRef, hoveredCommunity]
  );

  const mouseout = useCallback(
    (mapRef) => {
      if (mapRef && mapRef?.current) {
        mapRef.current.target.getCanvas().style.cursor = "grab";
        if (!hoveredCommunity) return;
        const { source, sourceLayer } = COMMUNITY_POINTS;
        mapRef?.current?.target.setFeatureState(
          {
            source,
            sourceLayer,
            id: hoveredCommunity.id,
          },
          {
            hover: false,
          }
        );
        hoveredCommunity = null;
      }
    },
    [mapRef, hoveredCommunity]
  );

  mapRef?.current?.on("click", (e) => {
    if (mapRef.current.getZoom() < 8) {
      return;
    }
    if (
      mapRef.current.queryRenderedFeatures(e.point)[0]?.layer?.id ===
      COMMUNITY_POINTS.id
    ) {
      return;
    }

    const selectedFeatures = mapRef.current.queryRenderedFeatures(e.point, {
      layers: [VECTOR_SERVER_TEST.id, "assigned_zips-layer"],
    });
    const instanceMap = e.target;
    const newBBox = bbox;
    let stackedLayers = selectedFeatures.map((f) => f.source);

    const divElement = document.createElement("div");

    if (stackedLayers.includes("assigned_zips")) {
      const assignedFeature =
        selectedFeatures[stackedLayers.indexOf("assigned_zips")];

      const marketName = getMarketName(
        JSON.stringify(assignedFeature.properties)
      );
      const popupContent =
        '<div class="map-community-popup-info">' +
        "<div><strong>" +
        assignedFeature.properties.name +
        "</strong></div>" +
        "<li><strong>" +
        "Current Market: </strong>" +
        marketName +
        "</li></div>";

      divElement.innerHTML = popupContent;

      if (selectedMarket && selectedMarket.name != marketName) {
        const assignBtn = document.createElement("div");
        if (
          userInfo &&
          userInfo.groups &&
          userInfo.groups.includes(MAP_EDITOR_GROUPNAME) &&
          selectedMarket.name !== marketName
        ) {
          assignBtn.innerHTML =
            `<button class="btn" > Add to ` + selectedMarket.name + `</button>`;
        }
        divElement.appendChild(assignBtn);
        let marketInput = (({ id, name, parent_area_id }) => ({
          id,
          name,
          parent_area_id,
        }))(selectedMarket);
        assignBtn.addEventListener("click", (e) => {
          assignMarket({
            variables: {
              zipName: assignedFeature.properties.name,
              market: marketInput,
            },
          }).then(() => {
            doRefetchWithNewVariables(instanceMap, newBBox);
          });
        });
        divElement.id = "market-popup";

        const popupElement = document.querySelector(".mapboxgl-popup");
        const parentElement = document.querySelector(".mapboxgl-map");
        const isPopupAdded = popupElement !== null;

        const popup = new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setDOMContent(divElement);
        if (isPopupAdded) {
          parentElement?.lastChild?.remove();
          popup.addTo(e.target);
        } else {
          popup.addTo(e.target);
        }
      } else {
        const popupElement = document.querySelector(".mapboxgl-popup");
        const parentElement = document.querySelector(".mapboxgl-map");
        const isPopupAdded = popupElement !== null;

        const popup = new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setDOMContent(divElement);
        if (isPopupAdded) {
          parentElement?.lastChild?.remove();
          popup.addTo(e.target);
        } else {
          popup.addTo(e.target);
        }
      }
    } else {
      const backgroundFeature =
        selectedFeatures[stackedLayers.indexOf(VECTOR_SERVER_TEST.source)];

      if (backgroundFeature === undefined) {
        return;
      }

      const marketName = getMarketName(
        JSON.stringify(backgroundFeature.properties)
      );
      const popupContent =
        '<div class="map-community-popup-info">' +
        "<div><strong>" +
        backgroundFeature.properties.name +
        "</strong></div>" +
        "<li><strong>" +
        "Current Market: </strong>" +
        marketName +
        "</li></div>";

      divElement.innerHTML = popupContent;

      if (selectedMarket && selectedMarket.name !== marketName) {
        const assignBtn = document.createElement("div");
        if (
          userInfo &&
          userInfo.groups &&
          userInfo.groups.includes(MAP_EDITOR_GROUPNAME) &&
          selectedMarket.name !== marketName
        ) {
          assignBtn.innerHTML =
            `<button class="btn" > Add to ` + selectedMarket.name + `</button>`;
        }
        divElement.appendChild(assignBtn);
        let marketInput = (({ id, name, parent_area_id }) => ({
          id,
          name,
          parent_area_id,
        }))(selectedMarket);
        assignBtn.addEventListener("click", (e) => {
          assignMarket({
            variables: {
              zipName: backgroundFeature.properties.name,
              market: marketInput,
            },
          }).then(() => {
            doRefetchWithNewVariables(instanceMap, newBBox);
          });
        });

        const popupElement = document.querySelector(".mapboxgl-popup");
        const isPopupAdded = popupElement !== null;
        const parentElement = document.querySelector(".mapboxgl-map");

        const popup = new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setDOMContent(divElement);
        if (isPopupAdded) {
          parentElement?.lastChild?.remove();
          popup.addTo(e.target);
        } else {
          popup.addTo(e.target);
        }
      } else {
        const popupElement = document.querySelector(".mapboxgl-popup");
        const isPopupAdded = popupElement !== null;
        const parentElement = document.querySelector(".mapboxgl-map");

        const popup = new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setDOMContent(divElement);
        if (isPopupAdded) {
          parentElement?.lastChild?.remove();
          popup.addTo(e.target);
        } else {
          popup.addTo(e.target);
        }
      }
    }
  });

  function getMarketName(jsonString) {
    try {
      const data = JSON.parse(jsonString);
      return data.market !== undefined ? data.market : "No Market";
    } catch (error) {
      return "Please, center the map on the zip code";
    }
  }

  const handleMouseEnter = useCallback(
    (e) => {
      if (e.features && e.features.length > 0) {
        mouseover(e.features[0], e.target || mapRef);
      } else {
        mouseout(mapRef);
      }
    },
    [mouseover, mouseout, mapRef]
  );

  const loadMapSourcesAndLAyers = (map) => {
    // setLoading(true);
    map.addSource("assigned_zips", {
      type: "geojson",
      data: featureCollection,
    });

    map.addLayer({
      id: "assigned_zips-layer",
      source: "assigned_zips",
      type: "fill",
      paint: {
        "fill-color": ["get", "color"],
        "fill-opacity": 0.3,
        "fill-outline-color": "black",
      },
      minzoom: 8,
      maxzoom: 22,
    });

    map.addSource(VECTOR_SERVER_TEST.source, {
      type: "vector",
      tiles: [VECTOR_SERVER_TEST.url],
      minzoom: 8,
      maxzoom: 22,
    });

    map?.addSource(MARKET_SHAPES.source, {
      type: "vector",
      tiles: [MARKET_SHAPES.url],
      minzoom: 0,
      maxzoom: 10,
    });

    // Add a new layer to visualize the polygon.
    map?.addLayer({
      id: VECTOR_SERVER_TEST.id,
      source: VECTOR_SERVER_TEST.source,
      "source-layer": VECTOR_SERVER_TEST.sourceLayer,
      type: "fill",
      paint: {
        "fill-color": "black",
        "fill-opacity": 0.05,
        "fill-outline-color": "black",
      },
    });
    map?.addLayer({
      id: VECTOR_SERVER_TEST.id + "-outline",
      source: VECTOR_SERVER_TEST.source,
      "source-layer": VECTOR_SERVER_TEST.sourceLayer,
      type: "line",
      paint: {
        "line-width": 0.2,
        "line-color": "black",
      },
    });

    map?.addLayer({
      id: MARKET_SHAPES.id,
      source: MARKET_SHAPES.source,
      "source-layer": MARKET_SHAPES.sourceLayer,
      type: "fill",
      paint: {
        "fill-color": {
          type: "identity",
          property: "color",
        },
        "fill-opacity": 0.4,
      },
      minzoom: 0,
      maxzoom: 10,
    });

    map.addLayer({
      id: "assigned_markets-layer-selected",
      source: "assigned_zips",
      type: "line",
      paint: {
        "line-width": 1,
        "line-color": "blue",
      },
      filter: ["==", ["get", "parent_area_id"], ""],
    });

    map.addLayer({
      id: "assigned_zips-layer-selected",
      source: "assigned_zips",
      type: "line",
      paint: {
        "line-width": 1,
        "line-color": "red",
      },
      filter: ["==", ["get", "market_id"], ""],
    });

    map.addSource(COMMUNITY_POINTS.source, {
      type: "vector",
      tiles: [COMMUNITY_POINTS.url],
      minzoom: 0,
      maxzoom: 22,
      promoteId: "area_id",
    });

    // Add a new layer to visualize the polygon.
    map.addLayer({
      id: COMMUNITY_POINTS.id,
      source: COMMUNITY_POINTS.source,
      "source-layer": COMMUNITY_POINTS.sourceLayer,
      type: "circle",
      paint: {
        "circle-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          "#19d14a",
          "#e00b0b",
        ],
        "circle-radius": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          7,
          5,
        ],
        "circle-stroke-color": "white",
        "circle-stroke-width": 1,
      },
    });
  };

  return (
    <>
      {popupLngLat && (
        <Popup lngLat={popupLngLat} onClose={onPopupClose}>
          {content}
        </Popup>
      )}
      <StyledContainer ref={(el) => (mapContainer.current = el)} />
    </>
  );
};

Map.propTypes = {
  userInfo: PropTypes.object,
  onEventTrigger: PropTypes.func,
};

export default Map;
