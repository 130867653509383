import { UPDATE_MARKET_DATA, SELECT_MARKET, SET_MARKET_ID_FILTER } from './marketActions';

const initialState = {
    marketData: null,
    selectedMarket: null,
    marketIdFilter: [],
};

const marketReducer = (state = initialState, action) => {
    switch (action.type) {

        case UPDATE_MARKET_DATA:
            return {
                ...state,
                marketData: action.payload,
            };
        case SELECT_MARKET:
            return {
                ...state,
                selectedMarket: action.payload, 
            };
        case SET_MARKET_ID_FILTER:
            return {
                ...state,
                marketIdFilter: action.payload,
            };
        default:
            return state;
    }
};

export default marketReducer;