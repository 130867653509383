import React, { useState } from 'react';
import { useMutation, useSubscription } from '@apollo/client';
import { ADD_AGENT_TO_MARKET_VIA_MARKET } from '../../../graphql/mutations';
import { SUBSCRIBE_AGENTS } from '../../../graphql/subscriptions';
import Select from 'react-select';
import PropTypes from 'prop-types';

function AddNewAgent({market}) {
    const [selectedAgent, setSelectedAgent] = useState(null);
    const {data: agentList, loading: listLoading, error:listError} = useSubscription(SUBSCRIBE_AGENTS, {variables: {marketId: market.id}});
    const [addAgent, {loading, error}] = useMutation(ADD_AGENT_TO_MARKET_VIA_MARKET, {onCompleted: (data) => setSelectedAgent(null)});

    function handleSubmit(event) {
      event.preventDefault();
      const agentInput = (({id, name, email, salesforce_id, program}) => ({id, name, email, salesforce_id, program}))(selectedAgent);
      const marketInput = (({id, name, parent_area_id, friendly_name}) => ({id, name, parent_area_id, friendly_name}))(market);
      addAgent({
        variables: {
          agent_id: agentInput.id,
          market_id: marketInput.id,
        }
      })
    }

    if (loading || listLoading) return 'Submitting...';
    if (error || listError) {
        return `Submission error!`;
    }

    const agentOptions = agentList?.atm_agents.map(agent => (
      {
        ...agent,
        value: agent.salesforce_id,
        label: agent.name,
      }
    ));

    const colourStyles = {
      option: (styles, {}) => {
        return {
          ...styles,
          color: 'black',
        };
      },
    };

    return (
      <div className="agent-select">
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue=''
          isSearchable={true}
          onChange={(e) => setSelectedAgent(e)}
          name="label"
          options={agentOptions}
          styles={colourStyles}
        />
        <button disabled={!selectedAgent} onClick={handleSubmit}>Add</button>
      </div>
    );
}

AddNewAgent.propTypes = {
    market: PropTypes.object.isRequired
}

export default AddNewAgent;
