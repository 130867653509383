import { gql } from "@apollo/client";

export const CREATE_NEW_MARKET_VIA_AREA = gql`
  mutation CreateMarketViaAreaReference(
    $areaName: String
    $tier: Int
    $marketName: String
    $friendlyName: String
  ) {
    insert_atm_areas_one(
      object: {
        name: $areaName
        markets: { data: { name: $marketName, friendly_name: $friendlyName } }
        tier: $tier
      }
      on_conflict: { update_columns: updated_at, constraint: areas_name_key }
    ) {
      name
      markets {
        name
        friendly_name
      }
    }
  }
`;

export const CREATE_NEW_AREA = gql`
  mutation CreateNewArea($areaName: String, $tier: Int, $salesManagerId: Int) {
    insert_atm_areas_one(
      object: {
        name: $areaName
        tier: $tier
        sales_manager_id: $salesManagerId
      }
      on_conflict: { update_columns: updated_at, constraint: areas_name_key }
    ) {
      id
      name
      tier
      sales_manager_id
    }
  }
`;

export const ASSIGN_ZIP_TO_MARKET = gql`
  mutation AssignZip($zipName: String, $market: atm_markets_insert_input!) {
    insert_atm_zip_codes_one(
      object: {
        name: $zipName
        market: {
          data: $market
          on_conflict: {
            constraint: markets_name_key
            update_columns: updated_at
          }
        }
      }
      on_conflict: {
        constraint: zip_codes_name_key
        update_columns: [market_id, geom]
      }
    ) {
      name
    }
  }
`;

export const ADD_AGENT_TO_MARKET_VIA_MARKET = gql`
  mutation insertAgentMarketAvailability($agent_id: Int!, $market_id: Int!) {
      insert_atm_agent_market_availabilities_one(object: {
        agent_id: $agent_id, 
        market_id: $market_id
      }, 
      on_conflict: {
        constraint: agent_market_availabilities_pkey, 
        update_columns: [agent_id, market_id]
      }) {
        agent_id
        market_id
    }
  }
`;

export const DELETE_AGENT_FROM_MARKET = gql`
  mutation DeleteAgentFromMarket($id: bigint!) {
    delete_atm_agent_market_availabilities_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATE_DAYS = gql`
  mutation MyMutation(
    $id: bigint!
    $days: atm_agent_market_availabilities_set_input!
  ) {
    update_atm_agent_market_availabilities_by_pk(
      pk_columns: { id: $id }
      _set: $days
    ) {
      id
    }
  }
`;

export const DELETE_AREA = gql`
  mutation DeleteArea($id: Int!) {
    delete_atm_areas_by_pk(id: $id) {
      id
    }
  }
`;

export const DELETE_MARKET = gql`
  mutation DeleteMarket($id: Int!) {
    delete_atm_markets_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATE_MARKET_COLOR = gql`
  mutation UpdateMarketColor($color: String!, $marketId: Int!) {
    update_atm_markets_by_pk(
      pk_columns: { id: $marketId }
      _set: { color: $color }
    ) {
      color
      name
      id
    }
  }
`;

export const UPDATE_MARKET_PARENT_AREA = gql`
  mutation UpdateMarketParentArea($marketId: Int!, $areaId: Int!) {
    update_atm_markets_by_pk(
      pk_columns: { id: $marketId }
      _set: { parent_area_id: $areaId }
    ) {
      id
      parent_area_id
    }
  }
`;

export const UPDATE_AREA_TIER = gql`
  mutation UpdateAreaTier($areaId: Int!, $newTier: Int!) {
    update_atm_areas_by_pk(
      pk_columns: { id: $areaId }
      _set: { tier: $newTier }
    ) {
      id
      tier
    }
  }
`;

export const UPDATE_AREA_SALES_MANAGER = gql`
  mutation UpdateAreaWithSalesManager($areaId: Int!, $salesManagerId: Int!) {
    update_atm_areas_by_pk(
      pk_columns: { id: $areaId }
      _set: { sales_manager_id: $salesManagerId }
    ) {
      id
    }
  }
`;

export const UPDATE_MARKET_FRIENDLY_NAME = gql`
  mutation UpdateMarketFriendlyName($id: Int!, $friendlyName: String!) {
    update_atm_markets_by_pk(
      pk_columns: { id: $id }
      _set: { friendly_name: $friendlyName }
    ) {
      id
      friendly_name
    }
  }
`;