import React, { useEffect , useState } from 'react';
import { useSubscription } from '@apollo/client';
import '../AreaList/AreaList.css';
import { NOTIFY_ZIP_MARKETS } from '../../graphql/subscriptions';
import ZipSelect from '../MarketDetails/ZipSelect';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setZipList } from '../../redux/zipActions';

const ZipList = ({ searchQuery , onDataEmpty , onDataFound }) => {
  const dispatch = useDispatch();
  const zipList = useSelector(state => state.zips.zipList);
  const [ isLoading, setIsLoading ] = useState(false);

  useSubscription(NOTIFY_ZIP_MARKETS, {
    variables: { name: '%' + searchQuery + '%' },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data) {
        setIsLoading(false);
        dispatch({ type: 'FETCH_ZIP_LIST_SUCCESS', payload: subscriptionData.data.atm_zip_codes });
        if (subscriptionData.data.atm_zip_codes.length === 0) {
          onDataEmpty();
          dispatch(setZipList([]));
        } else {
          dispatch(setZipList(subscriptionData.data.atm_zip_codes));
          onDataFound();
        }
      }
    },
    onError: (error) => {
      setIsLoading(false);
      dispatch({ type: 'FETCH_ZIP_LIST_FAILURE', payload: error })
    },
    onStart: () => setIsLoading(true),
  });

  useEffect(() => {
    setIsLoading(true);
    dispatch({ type: 'FETCH_ZIP_LIST_REQUEST', payload: searchQuery });
  }, [dispatch, searchQuery])

  return (
    <div className="area-list-container">
        { isLoading && <div>Zip Searching...</div>}
        {
            zipList?.length > 0 &&
                <ZipSelect zipcodes={zipList} key={'area-list-data'}/>
        }
      </div>
  )
};

ZipList.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  onDataEmpty: PropTypes.func.isRequired,
  onDataFound: PropTypes.func.isRequired
}

export default ZipList;
