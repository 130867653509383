import {
  GET_SALESMANAGERS_REQUEST,
  GET_SALESMANAGERS_SUCCESS,
  GET_SALESMANAGERS_FAILURE,
  FETCH_AGENT_ASSIGNMENTS_REQUEST,
  FETCH_AGENT_ASSIGNMENTS_SUCCESS,
  FETCH_AGENT_ASSIGNMENTS_FAILURE,
} from "./agentActions";
 
const initialState = {
  salesmanagers: [],
  agents: [],
  loading: false,
  error: null,
};

const agentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SALESMANAGERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SALESMANAGERS_SUCCESS:
      return {
        ...state,
        salesmanagers: action.payload,
        loading: false,
        error: null,
      };
    case GET_SALESMANAGERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_AGENT_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_AGENT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        agents: action.payload.agents,
        error: null,
      };
    case FETCH_AGENT_ASSIGNMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        agents: [],
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default agentReducer;
