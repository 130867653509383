import React from 'react';
import './MarketCharts.css';
import {useSubscription} from "@apollo/client";
import {SUBSCRIBE_LATEST_MARKET_DISTRIBUTION} from "../../graphql/subscriptions";
import PieChart from "./PieChart";
import { useSelector } from 'react-redux';

const MarketCharts = () => {
    const selectedMarket = useSelector(state => state.markets?.selectedMarket);

    const { loading, error, data } = useSubscription(SUBSCRIBE_LATEST_MARKET_DISTRIBUTION, {variables: {id: selectedMarket?.id}});

    return (
      <div className="market-charts-panel">
          {data ? <PieChart marketDistributionData={data}/> : loading ? <p>Loading Chart</p>:<p>No Data</p>}
      </div>
    );
}

export default MarketCharts;
