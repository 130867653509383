import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import mapReducer from "./mapReducer";
import areasReducer from "./areasReducer";
import agentReducer from "./agentReducer";
import marketReducer from "./marketReducer";
import zipReducer from "./zipReducer";

const rootReducer = combineReducers({
  map: mapReducer,
  areas: areasReducer,
  markets: marketReducer,
  agents: agentReducer,
  zips: zipReducer,
});

const middleware = [thunk];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middleware),
});

export default store;
