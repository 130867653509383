// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.market-charts-panel {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 12px;
  background-color: #212E71B1;
  color: #ffffff;
  z-index: 100;
  font-weight: bold;
  border-radius: 5px;
  min-width: 350px;
}

.market-charts-wrapper {
  padding: 6px 24px;
}

`, "",{"version":3,"sources":["webpack://./src/components/MarketCharts/MarketCharts.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,YAAY;EACZ,2BAA2B;EAC3B,cAAc;EACd,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".market-charts-panel {\n  display: inline-block;\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  margin: 12px;\n  background-color: #212E71B1;\n  color: #ffffff;\n  z-index: 100;\n  font-weight: bold;\n  border-radius: 5px;\n  min-width: 350px;\n}\n\n.market-charts-wrapper {\n  padding: 6px 24px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
