import { gql } from "@apollo/client";

export const QUERY_MARKET_DETAILS = gql`
  query MarketDetails($id: Int!) {
    atm_markets_by_pk(id: $id) {
      name
      zip_codes {
        id
        name
      }
      agent_market_availabilities(
        order_by: { agent: { name: asc } }
        where: { agent: { eligible: { _eq: true } } }
      ) {
        agent {
          id
          name
          program
        }
        available_monday
        available_tuesday
        available_wednesday
        available_thursday
        available_friday
        available_saturday
        available_sunday
        id
      }
    }
  }
`;

export const QUERY_ZIP_CODES = gql`
  query MarketDetails($id: Int!) {
    atm_markets_by_pk(id: $id) {
      zip_codes {
        id
        name
        geom
        market_id
      }
    }
  }
`;

export const NOTIFY_WILDLY_UNBALANCED_MARKETS = gql`
  query WildlyUnbalancedMarkets {
    market_observer_latest_observations_wildly_unbalanced(
      distinct_on: market_atm_id
    ) {
      market_atm_id
    }
  }
`;

export const NOTIFY_AGENT_ZERO_LISTINGS_MARKETS = gql`
  query AgentZeroListingsMarkets {
    market_observer_latest_observations_zero_leads(distinct_on: market_atm_id) {
      market_atm_id
    }
  }
`;

export const NOTIFY_AGENT_COLD_START_MARKETS = gql`
  query AgentColdStartMarkets {
    market_observer_latest_observations_cold_start(distinct_on: market_atm_id) {
      market_atm_id
    }
  }
`;


export const GET_SALES_MANAGER = gql`
  query GetSalesManager($_eq: Int = 0) {
    atm_sales_manager(where: { id: { _eq: $_eq } }) {
      id
      name
    }
  }
`;

export const GET_ALL_SALES_MANAGERS = gql`
  query GetAllSalesManagers {
    atm_sales_manager {
      id
      name
    }
  }
`;
