import React from "react";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useMutation } from "@apollo/client";
import { UPDATE_DAYS } from "../../../graphql/mutations";
import { useIsUserInGroup } from "../../../oktaUtils";
import { MAP_EDITOR_GROUPNAME } from "../../../utils";
import PropTypes from "prop-types";

const DAYS = [
    {
        key: "sunday",
        label: "S"
    },
    {
        key: "monday",
        label: "M"
    },
    {
        key: "tuesday",
        label: "T"
    },
    {
        key: "wednesday",
        label: "W"
    },
    {
        key: "thursday",
        label: "T"
    },
    {
        key: "friday",
        label: "F"
    },
    {
        key: "saturday",
        label: "S"
    }
];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
        margin: theme.spacing(0.5),
        padding: theme.spacing(0, 1),
        "&:not(:first-of-type)": {
            border: "1px solid",
            borderColor: "#692B7C",
            borderRadius: "50%"
        },
        "&:first-of-type": {
            border: "1px solid",
            borderColor: "#692B7C",
            borderRadius: "50%"
        }
    }
}));

const StyledToggle = styled(ToggleButton)(({ theme }) => ({
    color: "gray",
    background: "#800000",
    "&.Mui-selected": {
        color: "white",
        background: "#2eb82e"
    },
    "&:hover": {
        borderColor: "#BA9BC3",
        background: "#cc0000"
    },
    "&:hover.Mui-selected": {
        borderColor: "#53ff1a",
        background: "#53ff1a"
    },
    minWidth: 22,
    maxWidth: 22,
    height: 22,
    textTransform: "unset",
    fontSize: "0.75rem"
}));

const WeekdayPicker = ({ agent_market_availability, userInfo }) => {
    const isUserInMapEditGroup = useIsUserInGroup(MAP_EDITOR_GROUPNAME, userInfo);

    const [updateDays] = useMutation(UPDATE_DAYS);
    const dbDays = {
        available_sunday: agent_market_availability.available_sunday,
        available_monday: agent_market_availability.available_monday,
        available_tuesday: agent_market_availability.available_tuesday,
        available_wednesday: agent_market_availability.available_wednesday,
        available_thursday: agent_market_availability.available_thursday,
        available_friday: agent_market_availability.available_friday,
        available_saturday: agent_market_availability.available_saturday
    };
    const indices = Object.values(dbDays).reduce(
        (out, bool, index) => bool ? out.concat(index) : out,
        []
    );

    const handleClick = (event, value) => {
        if (isUserInMapEditGroup) {
            dbDays.available_sunday = value.includes(0);
            dbDays.available_monday = value.includes(1);
            dbDays.available_tuesday = value.includes(2);
            dbDays.available_wednesday = value.includes(3);
            dbDays.available_thursday = value.includes(4);
            dbDays.available_friday = value.includes(5);
            dbDays.available_saturday = value.includes(6);
            updateDays({ variables: { id: agent_market_availability.id, days: dbDays } });
        }
    };

    return (
        <div className="weekday-picker">
            <StyledToggleButtonGroup
                size="small"
                aria-label="Days of the week"
                value={indices}
                onChange={handleClick}
            >
                {DAYS.map((day, index) => (
                    <StyledToggle key={day.key} value={index} aria-label={day.key}>
                        {day.label}
                    </StyledToggle>
                ))}
            </StyledToggleButtonGroup>
        </div>
    );
};

WeekdayPicker.propTypes = {
    agent_market_availability: PropTypes.object.isRequired,
    userInfo: PropTypes.object
};

export default WeekdayPicker;
