import React from 'react';
import {useMutation} from '@apollo/client';
import { ASSIGN_ZIP_TO_MARKET } from '../../graphql/mutations';
import { useIsUserInGroup } from '../../oktaUtils';
import { MAP_EDITOR_GROUPNAME } from '../../utils';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';


const PopupContent = ({selectedFeature, userInfo}) => {
    const selectedMarket = useSelector(state => state.markets?.selectedMarket);

    const [assignMarket, {loading, error}] = useMutation(ASSIGN_ZIP_TO_MARKET);
    const isUserInMapEditGroup = useIsUserInGroup(MAP_EDITOR_GROUPNAME, userInfo);

    function handleClick() {
        let marketInput = (({id, name, parent_area_id}) => ({id, name, parent_area_id}))(selectedMarket);
        assignMarket({variables: {zipName: selectedFeature.properties.name, market: marketInput}})
    }

    if (loading) return 'Submitting...';
    if (error) return `Submission error! ${error.message}`;

    return (
        <div>
            <h3>{selectedFeature.properties.name}</h3>
            {selectedFeature.properties.market_id ? <ul>
                <li><b>Current Market</b>: {JSON.parse(selectedFeature.properties.market).name}</li>
            </ul> : null}
            {
                isUserInMapEditGroup ?
                (<button onClick={handleClick} disabled={!selectedMarket}>{selectedMarket ? 'Add to ' + selectedMarket.name : 'Select a Market to assign'}</button>)
                : null
            }   
        </div>
    );
}

PopupContent.propTypes = {
    selectedFeature: PropTypes.object.isRequired,
    userInfo: PropTypes.object
};

export default PopupContent;
