export function getErrorMessage(errorMessage){
    if (errorMessage.includes('agent_market_availabilities')) {
      return 'The Market cannot be deleted if it has agents related, please delete the agents assigned first.';
    }
    if (errorMessage.includes('zip_codes_market_id_fkey')) {
      return 'The market cannot be deleted because it has zip codes assigned, please remove them before deleting this market.';
    }
    if (errorMessage.includes('markets_parent_area_id_fkey')) {
      return 'The area cannot be deleted because it has markets assigned, please remove the markets before deleting the area.';
    }
    else return null;
  }