import {
  ACCESS_TOKEN,
  GEOCODING_API_ROOT,
} from './mapboxConstants';

const handleSuccess = async (response) => {
  const responseBody = await response.text();
  return {
    body: responseBody?.length ? JSON.parse(responseBody) : response,
    statusCode: response.status,
  };
};

export const fetchZip = async (zipcode) => {
  const uri = `${GEOCODING_API_ROOT}/mapbox.places/${zipcode}.json`;

  return fetch(uri + '?' + new URLSearchParams({
    country: 'us',
    types: 'postcode',
    access_token: ACCESS_TOKEN,
  })).then(async (response) => {
    if (response.status < 200 || response.status >= 300) {
      console.log(zipcode + ' zip not found!');
    }

    return handleSuccess(response);
  });
};

