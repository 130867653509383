export const SELECT_ZIP = 'SELECT_ZIP';
export const SET_OPEN_ZIPS = 'SET_OPEN_ZIPS';
export const SET_ZIP_LIST = 'SET_ZIP_LIST';
export const FETCH_ZIP_LIST_REQUEST = 'FETCH_ZIP_LIST_REQUEST';
export const FETCH_ZIP_LIST_SUCCESS = 'FETCH_ZIP_LIST_SUCCESS';
export const FETCH_ZIP_LIST_FAILURE = 'FETCH_ZIP_LIST_FAILURE';

export const selectZip = (zip) => ({
    type: SELECT_ZIP,
    payload: zip,
});

export const setOpenZips = (openZips) => ({
    type: SET_OPEN_ZIPS,
    payload: openZips,
});

export const setZipList = (zips) => ({
    type: SET_ZIP_LIST,
    payload: zips,
});

export const fetchZipListRequest = (searchQuery) => ({
    type: FETCH_ZIP_LIST_REQUEST,
    payload: { searchQuery },
});

export const fetchZipListSuccess = (agents) => ({
    type: FETCH_ZIP_LIST_SUCCESS,
    payload: { agents },
});

export const fetchZipListFailure = (error) => ({
    type: FETCH_ZIP_LIST_FAILURE,
    payload: { error },
});