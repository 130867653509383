import React, { useContext, useRef, useState } from "react";
import DeleteItem from "../Shared/DeleteItem";
import { TwitterPicker } from "react-color";
import cn from "classnames";
import { useMutation, useLazyQuery } from "@apollo/client";
import { UPDATE_MARKET_COLOR } from "../../graphql/mutations";
import { QUERY_MARKET_DETAILS } from "../../graphql/queries";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { fetchZip } from "../Map/mapboxApi";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { selectMarket } from "../../redux/marketActions";
import { setOpenZips } from "../../redux/zipActions";
import MapContext from "../MapParent/MapContext";

const Market = ({ market, userInfo }) => {
  const dispatch = useDispatch();

  const [updateColor] = useMutation(UPDATE_MARKET_COLOR);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const mapRef = useContext(MapContext);
  
  const selectedMarket  = useSelector((state) => state.markets?.selectedMarket);

  const ref = useRef(null);
  useOnClickOutside(
    ref,
    () => setDisplayColorPicker(false),
    displayColorPicker
  );
  const [getMarketDetails, { loading, error, data }] =
    useLazyQuery(QUERY_MARKET_DETAILS);

  const getMarketLngLat = async (queryData) => {
    if (loading) {
      return null;
    }
    if (error) {
      console.error("Error loading market details:", error);
      return null;
    }

    const zips = queryData?.data?.atm_markets_by_pk.zip_codes;
    if (zips && zips.length > 0) {
      const zip = await fetchZip(zips[0].name);
      if (zip.body.features.length === 0 || !zip.body.features[0].center) {
        console.error("No coordinates returned from fetchZip");
        window.alert('This zip is not available in the map ' + zips[0].name);
      }
      return zip.body.features[0].center;
    } else {
      console.log("No zip codes available for market");
    }

    return null;
  };

  const handleChange = (color) => {
    updateColor({ variables: { marketId: market.id, color: color.hex } });
    setDisplayColorPicker(false);
  };

  const waitForMapLoad = async (map, callback, retries = 5, interval = 500) => {
    for (let i = 0; i < retries; i++) {
      if (map && map?.current?.loaded()) {
        callback();
        return true;
      }
      await new Promise(resolve => setTimeout(resolve, interval));
    }
    return false;
  };

  const flyToLocation = (lngLat) => {
    mapRef?.current?.flyTo({
      center: lngLat,
      essential: true,
      zoom: 9,
    });
  };
  
  const handleClick = async (market) => {
    try {
      mapRef?.current?.setFilter("assigned_zips-layer-selected", [
        "==",
        ["get", "market_id"],
        market?.id,
      ]);
      const queryData = await getMarketDetails({
        variables: { id: market.id },
      });
      const lngLat = await getMarketLngLat(queryData);
      const loaded = await waitForMapLoad(mapRef, () => flyToLocation(lngLat));
      if (!loaded) {
        console.error("Map is not loaded after retries");
      }
    } catch (error) {
      console.error(
        "Failed to get market details or longitude and latitude:",
        error
      );
    }
  };

  return (
    <div className="market-panel" id={"market-" + market.id}>
      <div
        className="market-color-swatch"
        onClick={() => setDisplayColorPicker(!displayColorPicker)}
        style={{ background: market.color }}
      />
      {displayColorPicker && (
        <div className="color-picker-popover" ref={ref}>
          <TwitterPicker color={market.color} onChange={handleChange} />
        </div>
      )}
      <h5
        className={cn(
          "market-name",
          selectedMarket?.id === market.id ? "selected" : ""
        )}
        onClick={() => {
          dispatch(selectMarket(market));
          dispatch(setOpenZips(false));
          handleClick(market);
        }}
      >
        {market.name}
      </h5>
      <DeleteItem userInfo={userInfo} id={market.id} type="market" />
    </div>
  );
};

Market.propTypes = {
  market: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
};

export default Market;
