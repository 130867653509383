const logErrorToCloudWatch = async (error, type) => {
    try {
      const isDev = window.location.href.includes("dev") || window.location.href.includes("localhost");
      const baseUrl = isDev
        ? "https://dev-restapi.leadqualification.neighborhoods.com"
        : "https://restapi.leadqualification.neighborhoods.com";
      const url = `${baseUrl}/logevent/maptool`;
  
      const logData = {
        timestamp: Date.now(),
        message: JSON.stringify({
          error: error?.message,
          stack: error?.stack,
          type: type
        }),
      };
  
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(logData),
      });
  
      if (!response.ok) {
        console.error("Failed to log error:", response.statusText);
      }
    } catch (loggingError) {
      console.error("Error logging to CloudWatch endpoint:", loggingError);
    }
  };
  
  export default logErrorToCloudWatch;