export const GET_SALESMANAGERS_REQUEST = "GET_SALESMANAGERS_REQUEST";
export const GET_SALESMANAGERS_SUCCESS = "GET_SALESMANAGERS_SUCCESS";
export const GET_SALESMANAGERS_FAILURE = "GET_SALESMANAGERS_FAILURE";

export const getSalesManagersRequest = () => ({
  type: GET_SALESMANAGERS_REQUEST,
});

export const getSalesManagersSuccess = (salesManagers) => ({
  type: GET_SALESMANAGERS_SUCCESS,
  payload: salesManagers,
});

export const getSalesManagersFailure = (error) => ({
  type: GET_SALESMANAGERS_FAILURE,
  payload: error,
});

export const FETCH_AGENT_ASSIGNMENTS_REQUEST = 'FETCH_AGENT_ASSIGNMENTS_REQUEST';
export const FETCH_AGENT_ASSIGNMENTS_SUCCESS = 'FETCH_AGENT_ASSIGNMENTS_SUCCESS';
export const FETCH_AGENT_ASSIGNMENTS_FAILURE = 'FETCH_AGENT_ASSIGNMENTS_FAILURE';

export const fetchAgentAssignmentsRequest = (searchQuery) => ({
    type: FETCH_AGENT_ASSIGNMENTS_REQUEST,
    payload: { searchQuery },
});

export const fetchAgentAssignmentsSuccess = (agents) => ({
    type: FETCH_AGENT_ASSIGNMENTS_SUCCESS,
    payload: { agents },
});

export const fetchAgentAssignmentsFailure = (error) => ({
    type: FETCH_AGENT_ASSIGNMENTS_FAILURE,
    payload: { error },
});