export const SET_AREAS = 'SET_AREAS';
export const SET_AREA_LOADING = 'SET_AREA_LOADING';

export const setAreas = (areas) => ({
  type: SET_AREAS,
  payload: areas,
});

export const setAreaLoading = (isLoading) => {
  return {
    type: SET_AREA_LOADING,
    payload: isLoading,
  };
};