import React, { useMemo, useState } from "react";
import cn from "classnames";
import AddNewAgent from "./agents/AddNewAgent";
import WeekdayPicker from "./agents/WeekdayPicker";
import DeleteAgentFromMarket from "./agents/DeleteAgentFromMarket";
import AreaSelect from "./AreaSelect";
import ZipSelect from "./ZipSelect";
import "./MarketDetails.css";
import { useIsUserInGroup } from "../../oktaUtils";
import { MAP_EDITOR_GROUPNAME } from "../../utils";
import PropTypes from "prop-types";
import { useMutation, useApolloClient } from "@apollo/client";
import { UPDATE_MARKET_FRIENDLY_NAME } from "../../graphql/mutations";
import { QUERY_ZIP_CODES } from "../../graphql/queries";
import { selectMarket } from "../../redux/marketActions";
import { setOpenZips } from "../../redux/zipActions";
import { useDispatch, useSelector } from "react-redux";

const MarketDetails = ({ market, marketDetails, userInfo }) => {
  const client = useApolloClient(); // Get the Apollo Client instance
  const dispatch = useDispatch();

  const [showAddAgent, setShowAddAgent] = useState(false);
  const openZips = useSelector((state) => state.zips.openZips);


  const isUserInMapEditGroup = useIsUserInGroup(MAP_EDITOR_GROUPNAME, userInfo);
  const [isAgentListVisible, setIsAgentListVisible] = useState(true);
  const [friendlyName, setFriendlyName] = useState(market.friendly_name || "");
  const [updateMarketFriendlyName] = useMutation(UPDATE_MARKET_FRIENDLY_NAME);

  const {
    agent_market_availabilities = [],
    name = "",
  } = marketDetails || {};


  const zip_codes = market?.zip_codes || [];
  const showZips = zip_codes.length > 0;



  const agents = useMemo(
    () =>
      agent_market_availabilities.map((agent_market_availability) => (
        <li
          key={"market-detail-agent-" + agent_market_availability.agent.id}
          className="agent-market-availability"
        >
          <div className="market-detail-agent-name">
            {agent_market_availability.agent.name} -{" "}
            {agent_market_availability.agent.program === "neighborhoods.com"
              ? "NH"
              : agent_market_availability.agent.program === "55places.com"
                ? "55p"
                : agent_market_availability.agent.program === "Both"
                  ? "Both"
                  : ""}
          </div>
          <WeekdayPicker
            userInfo={userInfo}
            agent_market_availability={agent_market_availability}
          />
          <DeleteAgentFromMarket
            userInfo={userInfo}
            agent_market_availability={agent_market_availability}
          />
        </li>
      )),
    [agent_market_availabilities, userInfo]
  );

  const toggleSidebar = () => {
    setIsAgentListVisible(!isAgentListVisible);
  };

  const handleSave = async (id, friendlyName) => {
    try {
      const { data } = await updateMarketFriendlyName({
        variables: {
          id,
          friendlyName,
        },
      });

      alert("Market Friendly Name Updated Successfully");
    } catch (error) {
      console.error("Failed to update market friendly name:", error);
    }
  };

  const [zipCodesData, setZipCodesData] = useState([]);

  const processZipCodesWithMarket = (zipCodes, market) => {
    return zipCodes.map(zipCode => ({
      ...zipCode,
      market: {
        id: market.id,
        name: market.name,
        friendlyName: market.friendlyName,
      },
    }));
  };

  const fetchZipCodes = async () => {
    setZipCodesData([]);
    try {
      const { data, error } = await client.query({
        query: QUERY_ZIP_CODES,
        variables: {
          id: market.id,
        },
      });
      if (error) {
        setZipCodesData([]);
      } else {
        const processedZipCodes = processZipCodesWithMarket(data?.atm_markets_by_pk?.zip_codes, market);
        setZipCodesData(processedZipCodes);
      }
    } catch (error) {
      setZipCodesData([]);
    }
  };

  return (
    <div className="market-details-panel">
      <button
        className="btn-close"
        onClick={() => {
          dispatch(selectMarket(null));
          dispatch(setOpenZips(false));
        }}
      />
      <div className="market-details-wrapper">
        <h2 className="market-details-header">{name}</h2>
        <form
          className="set-friendly-name"
          onSubmit={(e) => {
            e.preventDefault();
            handleSave(market.id, friendlyName);
          }}
        >
          <input
            type="text"
            className="set-friendly-name"
            placeholder="Friendly Name"
            value={friendlyName}
            disabled={!isUserInMapEditGroup}
            onChange={(e) => setFriendlyName(e.target.value)}
          />
          {isUserInMapEditGroup ? (
          <button type="submit" className="save-friendly-name-btn">
            Save
          </button>
          ) : null }
        </form>
        <AreaSelect
          marketId={market.id}
          userInfo={userInfo}
          marketParentAreaId={market.parent_area_id}
        />
        <div className="market-details-agents-wrapper">
          <h3 className="market-details-agents-header">
            Agents
            <button
              className={`${
                isAgentListVisible ? "hide-agents-button" : "show-agents-button"
              }`}
              onClick={toggleSidebar}
            ></button>
            {isUserInMapEditGroup ? (
              <button
                className={cn("btn-add-agent", showAddAgent ? "show" : "hide")}
                onClick={() => setShowAddAgent(!showAddAgent)}
              />
            ) : null}
          </h3>
          {showAddAgent && <AddNewAgent market={market} />}
          <ul className="market-details-agents-list">
            {isAgentListVisible && agents}
          </ul>
        </div>
        <div className="market-details-zipcodes-wrapper">
          <h3 className="market-details-zipcodes-header">
            Zips
            <button
              className={cn(
                "btn-collapse",
                openZips ? "show" : "hide"
              )}
              onClick={() => {
                dispatch(setOpenZips(!openZips));
                if (!openZips) {
                  fetchZipCodes();
                }
              }}
            />
          </h3>
          {(openZips) && (
            <ZipSelect zipcodes={zipCodesData} selectedMarket={market} />
          )}
        </div>
      </div>
    </div>
  );
};

MarketDetails.propTypes = {
  market: PropTypes.object.isRequired,
  marketDetails: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
};

export default MarketDetails;
