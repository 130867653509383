import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_AREA_TIER } from '../../graphql/mutations';
import { TIER_OPTIONS } from '../constants';
import { useIsUserInGroup } from "../../oktaUtils";
import { MAP_EDITOR_GROUPNAME } from "../../utils";
import PropTypes from 'prop-types';

const TierPicker = ({ areaId, areaTier, userInfo}) => {
  const isUserInMapEditGroup = useIsUserInGroup(MAP_EDITOR_GROUPNAME, userInfo);
  const [selected, setSelected] = useState(areaTier);
  const [updateAreaTier, {error}] = useMutation(UPDATE_AREA_TIER, {
    onCompleted: (data) => {
      const  { id, tier } = data.update_atm_areas_by_pk;
      alert(`Area ID ${id} has been updated to teir ${tier}.`);
      setSelected(tier);
    }
  });

  const handleChange = (event) => {
    event.preventDefault();
    const newTier = event.target.value;

    if (newTier === selected) return;

    if (isUserInMapEditGroup && window.confirm(`Do you want to set this area to tier ${newTier}?`)) {
      updateAreaTier({
        variables: {
          areaId: areaId,
          newTier: newTier,
        }
      });
    }
  };

  if (error) alert(`ERROR! There was an issue updating the tier for area ID ${areaId}.`);

  return (
    <div className="tier-picker">
      <label className="tier-picker-label">Tier:</label>
      <select
        className="tier-picker-select"
        onChange={handleChange}
        value={selected} // Keep this for a controlled component
      >
        {TIER_OPTIONS.map(({label, value}) => (
            <option
              key={value}
              value={value}
              className="tier-picker-option"
            >
              {label}
            </option>
        ))}
      </select>
    </div>
  );
};

TierPicker.propTypes = {
  areaId: PropTypes.number,
  areaTier: PropTypes.number,
  userInfo: PropTypes.object
};

export default TierPicker;
